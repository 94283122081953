



<!--<table class="table3">-->
<!--    <tr>-->
<!--        <td>Salary For EPF</td>-->
<!--        <td> : {{formatNumber(params["Basic-Salary"])}}</td>-->
<!--    </tr>-->
<!--    <tr>-->
<!--        <td>Employee Contribution To E.P.F.: 12%</td>-->
<!--        <td> : {{formatNumber(params["employeesContributionToEPF12"])}}</td>-->
<!--    </tr>-->
<!--    <tr>-->
<!--        <td>Employee Contribution To E.P.F.: 3%</td>-->
<!--        <td> : {{formatNumber(params["employeesContributionToEPF3"])}}</td>-->
<!--    </tr>-->
<!--</table>-->

<!--<div class="row margin-t-120">-->
<!--    <hr class="w-20">-->
<!--    <br/>-->
<!--    <br/>-->
<!--    <div class="margin-l-10">-->
<!--        AUTHORIZED SIGNATURE-->
<!--    </div>-->
<!--</div>-->
<!--<hr class="w-90">-->

<!--<div class="row margin-t-60">-->
<!--    <div class="margin-l-10"><b><u>ACKNOWLEDGEMENT</u></b></div>-->
<!--    <table class="table4 table-hover table-bordered table-striped"  style="text-align: center;" *ngIf="this.params.itemWiseCount">-->
<!--        <tbody>-->
<!--        <tr *ngFor="let indexElement of getItemList()">-->
<!--            <td>EMPLOYEE NAME</td>-->
<!--            <td> : -</td>-->
<!--        </tr>-->
<!--        <tr *ngFor="let indexElement of getItemList()">-->
<!--            <td>PROVIDENT FUND NO</td>-->
<!--            <td> : -</td>-->
<!--        </tr>-->
<!--        <tr *ngFor="let indexElement of getItemList()">-->
<!--            <td>NET SALARY RECIEVED</td>-->
<!--            <td> : <b>-</b></td>-->
<!--        </tr>-->
<!--        </tbody>-->
<!--    </table>-->

<!--    <div class="row margin-t-120">-->
<!--        <table class="table5">-->
<!--            <tbody>-->
<!--            <tr>-->
<!--                <td><hr class="dottedline" ></td>-->
<!--                <td><hr class="dottedline"></td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--                <td><div class="margin-l-20"> SIGNATURE</div></td>-->
<!--                <td><div class="margin-l-20"> DATE</div></td>-->
<!--            </tr>-->
<!--            </tbody>-->
<!--        </table>-->
<!--    </div>-->
<!--</div>-->


<div id="invoice">
    <div class="invoice overflow-auto">
        <div style="min-width: 600px">
            <header>
                <div class="row">
                    <div class="col">
                        <a target="_blank" href="https://lobianijs.com">
                            <img src="assets/images/logo/wide-logo-black.png" data-holder-rendered="true" style="width: 200px"/>
                        </a>
                    </div>
                    <div class="col company-details">
                        <h2 class="name">
                            <a target="_blank" href="https://lobianijs.com">
                                Unik Wear (Pvt) Ltd
                            </a>
                        </h2>
                        <div>No 245, Maya Mawatha, Kiribathgoda, LK</div>
                        <div>Reg No- PV 00221300</div>
                        <div>(94) 71-519-0899</div>
                        <div>info@unikwear.lk</div>
                    </div>
                </div>
            </header>
            <main>
                <div class="row contacts">
                    <div class="col invoice-to">
                        <div class="text-gray-light">INVOICE TO:</div>
                        <h2 class="to">{{params.name}}</h2>
                        <div class="address">Provident Number: {{params.providentFundNo}}</div>
                    </div>
                    <div class="col invoice-details">
                        <h1 class="invoice-id">Paysheet</h1>
                        <div class="date">{{params.startDate}} to {{params.endDate}}</div>
                    </div>
                </div>

                <table border="0" cellspacing="0" cellpadding="0">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th class="text-left">DESCRIPTION</th>
                        <th class="text-right">EARNINGS</th>
                        <th class="text-right">DEDUCTIONS</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td class="no">01</td>
                        <td class="text-left">
                            <h3>
                                Basic Salary
                            </h3>
                            Fixed monthly payment
                        </td>
                        <td class="qty">{{formatNumber(params["Basic-Salary"])}}</td>
                        <td class="unit">-</td>
                    </tr>
                    <tr>
                        <td class="no">02</td>
                        <td class="text-left">
                            <h3>
                                Sunday Incentive
                            </h3>
                            Special Sunday incentive for the employee
                        </td>
                        <td class="qty">{{formatNumber(params["Sunday-Allowance"])}}</td>
                        <td class="unit">-</td>
                    </tr>
                    <tr>
                        <td class="no">03</td>
                        <td class="text-left">
                            <h3>
                                Weekend Incentive
                            </h3>
                            Special Weekend incentive for the employee
                        </td>
                        <td class="qty">{{formatNumber(params["Weekend-Allowance"])}}</td>
                        <td class="unit">-</td>
                    </tr>
                    <tr>
                        <td class="no">04</td>
                        <td class="text-left">
                            <h3>
                                Night Duty Incentive
                            </h3>
                            Special Night Duty incentive for the employee
                        </td>
                        <td class="qty">{{formatNumber(params["NightDuty-Allowance"])}}</td>
                        <td class="unit">-</td>
                    </tr>
                    <tr>
                        <td class="no">05</td>
                        <td class="text-left">
                            <h3>
                                Poyaday Incentive
                            </h3>
                            Special Poyaday incentive for the employee
                        </td>
                        <td class="qty">{{formatNumber(params["PoyaDay-Allowance"])}}</td>
                        <td class="unit">-</td>
                    </tr>
                    <tr>
                        <td class="no">06</td>
                        <td class="text-left">
                            <h3>
                                Transport Incentive
                            </h3>
                            Special transport incentive for the employee
                        </td>
                        <td class="qty">{{formatNumber(params["Transport-Allowance"])}}</td>
                        <td class="unit">-</td>
                    </tr>
                    <tr>
                        <td class="no">07</td>
                        <td class="text-left">
                            <h3>
                                Budgetary Relief Allowance
                            </h3>
                        </td>
                        <td class="qty">{{formatNumber(params["Budgetary-Relief-Allowance"])}}</td>
                        <td class="unit">-</td>
                    </tr>
                    <tr>
                        <td class="no">08</td>
                        <td class="text-left">
                            <h3>
                                Living Cost Allowance
                            </h3>
                            Living cost allowance
                        </td>
                        <td class="qty">{{formatNumber(params["living-cost-allowance"])}}</td>
                        <td class="unit">-</td>
                    </tr>
                    <tr>
                        <td class="no">09</td>
                        <td class="text-left">
                            <h3>
                                Overtime
                            </h3>
                            Overtime payment for the working more than the regular time.
                        </td>
                        <td class="qty">{{formatNumber(params["Over-Time"])}}</td>
                        <td class="unit">-</td>
                    </tr>
                    <tr>
                        <td class="no">10</td>
                        <td class="text-left">
                            <h3>
                                Sales Commissions
                            </h3>
                            Special Commissions for sales which completed properly.
                        </td>
                        <td class="qty">{{formatNumber(params["Sales-Commission"])}}</td>
                        <td class="unit">-</td>
                    </tr>
                    <tr>
                        <td class="no">11</td>
                        <td class="text-left">
                            <h3>
                                Performance Incentive
                            </h3>
                            Special payment for all the special performances in working.
                        </td>
                        <td class="qty">{{formatNumber(params["Performance-Incentive"])}}</td>
                        <td class="unit">-</td>
                    </tr>
                    <tr>
                        <td class="no">12</td>
                        <td class="text-left">
                            <h3>
                                E.P.F Employee's Contribution
                            </h3>
                            E.P.F Payment Contribution from the employees side.
                        </td>
                        <td class="qty">-</td>
                        <td class="unit">{{formatNumber(params["E-P-F-Employees-Contribution"])}}</td>
                    </tr>
                    <tr>
                        <td class="no">13</td>
                        <td class="text-left">
                            <h3>
                                Salary Advances
                            </h3>
                            Pre-salary requests before the salary date.
                        </td>
                        <td class="qty">-</td>
                        <td class="unit">{{formatNumber(params["Salary-Advance"])}}</td>
                    </tr>
                    <tr>
                        <td class="no">13</td>
                        <td class="text-left">
                            <h3>
                                Insurance
                            </h3>
                            Insurance payment
                        </td>
                        <td class="qty">-</td>
                        <td class="unit">{{formatNumber(params["Insurance"])}}</td>
                    </tr>
                    <tr>
                        <td class="no">14</td>
                        <td class="text-left">
                            <h3>
                                Loans
                            </h3>
                            Deductions for the loans which applied before.
                        </td>
                        <td class="qty">-</td>
                        <td class="unit">{{formatNumber(params["Loans"])}}</td>
                    </tr>
                    <tr>
                        <td class="no">15</td>
                        <td class="text-left">
                            <h3>
                                Lunch
                            </h3>
                            Deduction for the lunch advance.
                        </td>
                        <td class="qty">-</td>
                        <td class="unit">{{formatNumber(params["Lunch"])}}</td>
                    </tr>

                    </tbody>
                    <tfoot>
                    <tr>
                        <td colspan="0"></td>
                        <td colspan="0">Total Earnings / Total Deductions</td>
                        <td>{{formatNumber(params["totalEarnings"])}}</td>
                        <td>{{formatNumber(params["deductions"])}}</td>
                    </tr>
                    <tr>
                        <td colspan="2"></td>
                        <td style="font-size: 2em" colspan="1">Net Salary</td>
                        <td style="font-size: 2em">{{formatNumber(params["netSalary"])}}</td>
                    </tr>
                    </tfoot>
                </table>
                <br/>
                <div class="thanks">Thank you!</div>
                <table class="table table-hover table-bordered table-striped"  style="text-align: center;" *ngIf="this.params.itemWiseCount">
                    <thead>
                    <tr>
                        <th scope="col">Item Name</th>
                        <th scope="col">Count</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let indexElement of getItemList()">
                        <td>{{indexElement.name}}</td>
                        <td>{{indexElement.qty}}</td>
                    </tr>
                    </tbody>
                </table>
                <div class="notices">
                    <div>Authorization</div>
                    <br/>
                    <br/>
                    <div class="notice">------------------------------------------------------------</div>
                    <div class="notice"><b>AUTHORIZED SIGNATURE</b></div>
                </div>
            </main>
            <br/><br/><br/><br/><br/><br/>
            <footer>
                <b>Invoice was created on a computer and it is not valid without the signature and seal.</b>
                <br/>
                ({{params.name}} who has provident number of {{params.providentFundNo}} got paid of {{formatNumber(params["netSalary"])}}.)
            </footer>
        </div>
        <!--        <div></div>-->
    </div>
</div>
